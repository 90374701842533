<template>
  <div>
    <CRow class="justify-content-center">
      <CCol xl="12" xxl="10">
        <Search @search="onSearch">
          <CCard>
            <CCardHeader>
              <slot name="header">
                <CIcon name="cil-storage" />
                <strong>{{$t('cloud.title.bridge_device')}}</strong>
                <span v-if="count > 0"> ({{count}}) </span>
              </slot>
            </CCardHeader>
            <CCardBody>
              <CDataTable
                :items="list"
                :fields="FIELDS"
                :items-per-page="10"
              >
                <td slot="status" slot-scope="{item}" class="align-middle">
                  <CBadge :color="$utility.getBadgeColor(item.status)">{{$t(`cloud.title.status_${item.status}`)}}</CBadge>
                </td>
                <td slot="model" slot-scope="{item}">
                  {{item.model?item.model:"AIBridge Pro"}}
                </td>
                <td slot="num_ch" slot-scope="{item}">
                  {{item.num_ch?item.num_ch:"8"}}
                </td>
                <td slot="note" slot-scope="{item}">
                  {{item.note?item.note:""}}
                </td>
                <td slot="swver" slot-scope="{item}">
                  {{item.swver?item.swver:""}}
                </td>
              </CDataTable>
            </CCardBody>
          </CCard>
        </Search>
        <CRow class="justify-content-end" v-if="pageTotal > 1">
          <CPagination
            :activePage.sync="pageCurrent"
            :pages="pageTotal"
            @update:activePage="onUpdatePage"
          >
          </CPagination>
          <CInput type="number" v-model="pageDirectSet" @change="onChangePageDirectSet" class="px-3 w-auto" style="max-width: 120px;" />
        </CRow>
      </CCol>
    </CRow>
  </div>
</template>

<script>
import axios from 'axios';
import qs from 'qs';
import Search from '@/containers/Search.vue'
import { mapState } from 'vuex'

export default {
  name: 'Devices',
  components: {
    Search
  },
  data () {
    return {
      FIELDS: [
        {key: 'id'},
        {key: 'name', label: this.$t('cloud.title.name')},
        {key: 'status', label: this.$t('cloud.title.status')},
        {key: 'alarm_cnt', label: 'Total'},
        {key: 'alarm_cnt_24', label: '24 H'},
        {key: 'img_cnt', label: 'Snapshot'},
        {key: 'mac_addr', label: 'MAC'},
        {key: 'swver', label: this.$t('cloud.title.swver')},
        {key: 'org', label: this.$t('cloud.title.organization')},
        {key: 'branch_name', label: this.$t('cloud.title.branch')}
      ],
      querying: false,
      pageTotal: 1,
      pageCurrent: 1,
      pageDirectSet: 1,
      count: 0,
      searchText: '',
      list: []
    }
  },
  mounted() {
    this.getList();
  },
  computed: {
    ...mapState([
      'capability'
    ])
  },
  methods: {
    getList() {
      let query_string = '';
      const params = {
        sort: 'id',
        // order: 'desc',
        search: this.searchText,
        page: this.pageCurrent
      };
      if (params) {
        query_string = '?' + qs.stringify(params)
      }
      axios.get(`/api/install/devices/${query_string}`)
        .then(result => {
          this.querying = false;
          this.list = this.$utility.copy(result.data.list);
          this.pageTotal = result.data.page.total;
          this.pageCurrent = result.data.page.current;
          this.count = result.data.page.count;
        })
        .catch(e => {
          console.error(e);
          this.querying = false;
        })
    },
    onSearch(text) {
      this.searchText = text;
      this.getList();
    },
    onUpdatePage() {
      this.pageDirectSet = ""+this.pageCurrent;
      this.getList();
    },
    onChangePageDirectSet() {
      if (!this.pageDirectSet || Number(this.pageDirectSet) <= 0) this.pageDirectSet = "1";
      else if (+this.pageDirectSet >= this.pageTotal) this.pageDirectSet = ""+this.pageTotal;
      this.pageCurrent = +this.pageDirectSet;
      this.getList();
    }
  }
}
</script>
