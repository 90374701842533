var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('CRow',{staticClass:"justify-content-center"},[_c('CCol',{attrs:{"xl":"12","xxl":"10"}},[_c('Search',{on:{"search":_vm.onSearch}},[_c('CCard',[_c('CCardHeader',[_vm._t("header",function(){return [_c('CIcon',{attrs:{"name":"cil-storage"}}),_c('strong',[_vm._v(_vm._s(_vm.$t('cloud.title.bridge_device')))]),(_vm.count > 0)?_c('span',[_vm._v(" ("+_vm._s(_vm.count)+") ")]):_vm._e()]})],2),_c('CCardBody',[_c('CDataTable',{attrs:{"items":_vm.list,"fields":_vm.FIELDS,"items-per-page":10},scopedSlots:_vm._u([{key:"status",fn:function(ref){
var item = ref.item;
return _c('td',{staticClass:"align-middle"},[_c('CBadge',{attrs:{"color":_vm.$utility.getBadgeColor(item.status)}},[_vm._v(_vm._s(_vm.$t(("cloud.title.status_" + (item.status)))))])],1)}},{key:"model",fn:function(ref){
var item = ref.item;
return _c('td',{},[_vm._v(" "+_vm._s(item.model?item.model:"AIBridge Pro")+" ")])}},{key:"num_ch",fn:function(ref){
var item = ref.item;
return _c('td',{},[_vm._v(" "+_vm._s(item.num_ch?item.num_ch:"8")+" ")])}},{key:"note",fn:function(ref){
var item = ref.item;
return _c('td',{},[_vm._v(" "+_vm._s(item.note?item.note:"")+" ")])}},{key:"swver",fn:function(ref){
var item = ref.item;
return _c('td',{},[_vm._v(" "+_vm._s(item.swver?item.swver:"")+" ")])}}])})],1)],1)],1),(_vm.pageTotal > 1)?_c('CRow',{staticClass:"justify-content-end"},[_c('CPagination',{attrs:{"activePage":_vm.pageCurrent,"pages":_vm.pageTotal},on:{"update:activePage":[function($event){_vm.pageCurrent=$event},_vm.onUpdatePage],"update:active-page":function($event){_vm.pageCurrent=$event}}}),_c('CInput',{staticClass:"px-3 w-auto",staticStyle:{"max-width":"120px"},attrs:{"type":"number"},on:{"change":_vm.onChangePageDirectSet},model:{value:(_vm.pageDirectSet),callback:function ($$v) {_vm.pageDirectSet=$$v},expression:"pageDirectSet"}})],1):_vm._e()],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }